<template>
    <div class="p-2 view-app d-flex align-items-center full-height">
        <header id="header" class="header header-bg-transparent header-abs-top">
            <div class="header-section py-1">
                <div id="logoAndNav" class="container">
                    <nav class="navbar navbar-left">
                        <img height="50" src="@/assets/logo.png" alt="Logo" />
                    </nav>
                </div>
            </div>
        </header>
        <loading v-if="loading" color="primary" class="mx-auto" :size="60" />
        <div v-show="!loading" class="w-100">
            <div
                v-show="linkValid"
                class="text-center mx-auto doc-parse-mobile-text-container"
            >
                <template v-if="fileSubmissionStatus === SUCCESS">
                    <i class="fas fa-check fa-4x text-success"> </i>
                    <h1 class="mt-3 display-2">Success</h1>
                    <p class="lead">
                        Continue on the browser on your computer, which will
                        automatically be updated with the information you just
                        scanned.
                    </p>
                    <btn
                        @click="$router.push({ name: 'Home' })"
                        app
                        class="mt-3"
                    >
                        Home
                    </btn>
                </template>
                <template v-else>
                    <h2 class="mb-3">
                        Take a picture of your {{ $route.query.doc_type }}
                    </h2>
                    <loading
                        v-if="fileSubmissionStatus === SUBMITTING"
                        :size="60"
                    />
                    <label v-else class="camera-file-upload btn btn-icon btn-primary">
                        <i class="fas fa-camera fa-2x"></i>
                        <input
                            class="d-none"
                            @change="submitFile($event.target.files[0])"
                            type="file"
                            accept="image/*"
                            capture="camera"
                        />
                    </label>
                    <!--
                    <div v-else class="camera-capture-input mb-3">
                        <i class="fas fa-camera fa-2x"></i>
                        <input
                            class="camera-capture-input-element"
                            @change="submitFile($event.target.files[0])"
                            type="file"
                            accept="image/*"
                            capture="camera"
                        />
                    </div>
                    -->
                    <p class="lead">
                        Make sure your {{ $route.query.doc_type }} is flat and
                        has good lighting.
                        <br />
                        Click the button above to open this device's camera.
                    </p>
                    <error-card v-if="fileSubmissionStatus === FAILURE">
                        Something went wrong. It looks like this link is no
                        longer valid. Please try again.
                    </error-card>
                </template>
            </div>
            <template v-if="!linkValid">
                <div class="d-flex justify-content-center w-100">
                    <div
                        class="text-center mx-auto doc-parse-mobile-text-container"
                    >
                        <img
                            class="img-fluid w-50"
                            :src="
                                require('@/assets/svg/icons/icon-61-orange.svg')
                            "
                            alt="SVG Illustration"
                        />
                        <p class="lead display-4 font-weight-normal">
                            Oops! Looks like this
                            <strong>link is invalid</strong>
                        </p>
                        <p class="lead">
                            For security, these links are only valid for 5
                            minutes. <br />
                            <br />
                            You can either go back to your computer and get
                            another link or
                            <a
                                @click="$router.push({ name: 'Contact' })"
                                class="link"
                                >let us know</a
                            >
                            if you think something went wrong on our end.
                            <btn
                                @click="$router.push({ name: 'Home' })"
                                app
                                class="mt-3"
                            >
                                Home
                            </btn>
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { unauthClient } from '@/api/index';

const IDLE = 0;
const SUBMITTING = 1;
const SUCCESS = 2;
const FAILURE = 3;

export default {
    data() {
        return {
            loading: true,
            linkValid: true,
            fileSubmissionStatus: IDLE,
        };
    },
    async created() {
        this.IDLE = IDLE;
        this.SUBMITTING = SUBMITTING;
        this.SUCCESS = SUCCESS;
        this.FAILURE = FAILURE;
        //await this.verifyLink();
    },
    async mounted() {
        this.loading = false;
    },
    methods: {
        async submitFile(file) {
            this.fileSubmissionStatus = SUBMITTING;
            var params = this.getQueryParamsForDocParseEndpoint();
            let formData = new FormData();
            formData.append('file', file);
            formData.append('file_type', file.type);
            try {
                await unauthClient.post('/doc-parse-mobile/', formData, {
                    params,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.fileSubmissionStatus = SUCCESS;
            } catch {
                this.fileSubmissionStatus = FAILURE;
            }
        },
        getQueryParamsForDocParseEndpoint() {
            var params = {
                token: this.$route.query.key,
                tr_id: this.$route.query.tr_id,
                doc_type: this.$route.query.doc_type,
                id: this.$route.query.id,
            };
            return params;
        },
        async verifyLink() {
            var params = this.getQueryParamsForDocParseEndpoint();
            try {
                this.linkValid = (
                    await unauthClient.get('/doc-parse-mobile/', {
                        params,
                    })
                ).data;
            } catch (e) {
                console.dir(e);
                this.linkValid = false;
            }
        },
        async startCamera() {
            let stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                },
                audio: false,
            });
            this.$refs.cameraFrame.srcObject = stream;
        },
        takePhoto() {
            var canvas = this.$refs.canvas;
            canvas
                .getContext('2d')
                .drawImage(
                    this.$refs.cameraFrame,
                    0,
                    0,
                    canvas.width,
                    canvas.height,
                );
            let imageDataUrl = canvas.toDataURL('image/jpeg');
            console.log(imageDataUrl);
        },
    },
};
</script>

<style>
.camera-file-upload {
    border-radius: 50%;
    width: 90px !important;
    height: 90px !important;
}
</style>

